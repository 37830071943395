// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleLeft       as fasFaAngleLeft       } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight      as fasFaAngleRight      } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faBars            as fasFaBars            } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faCaretDown       as fasFaCaretDown       } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretLeft       as fasFaCaretLeft       } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight      as fasFaCaretRight      } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faEnvelope        as fasFaEnvelope        } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faCircleCheck     as fasFaCircleCheck     } from "@fortawesome/pro-solid-svg-icons/faCircleCheck";
import { faCircleQuestion  as fasFaCircleQuestion  } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCircleUser      as fasFaCircleUser      } from "@fortawesome/pro-solid-svg-icons/faCircleUser";
import { faCircleXmark     as fasFaCircleXmark     } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import { faClock           as fasFaClock           } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment         as fasFaComment         } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFolder          as fasFaFolder          } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faPhone           as fasFaPhone           } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faTag             as fasFaTag             } from "@fortawesome/pro-solid-svg-icons/faTag";

import { faFacebook        as fabFaFacebook        } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faTwitter         as fabFaTwitter         } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faLinkedin        as fabFaLinkedin        } from "@fortawesome/free-brands-svg-icons/faLinkedin";

/**
 * Add solid icons
 */
library.add(fasFaAngleLeft, fasFaAngleRight, fasFaBars, fasFaCaretDown, fasFaCaretLeft, fasFaCaretRight, fasFaCircleCheck, fasFaCircleQuestion, fasFaCircleUser, fasFaClock, fasFaCircleXmark, fasFaComment, fasFaEnvelope, fasFaFolder, fasFaPhone, fasFaTag);


/**
 * Add brands icons
 */
library.add(fabFaFacebook, fabFaTwitter, fabFaLinkedin);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
